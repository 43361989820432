import * as React from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from '../components/container'
// import BigHero from 'components/big-hero'
// import theme from '../../config/theme'

// import {css} from '@emotion/react'
// import {bpMaxSM} from 'lib/breakpoints'

function MarkdownPage({children, pageContext: {frontmatter}}) {
  const {
    title,
    description,
    image,
    keywords,
  } = frontmatter

  return (
    <>
      <SEO title={title} description={description} image={image} keywords={keywords} />
      <Layout
        // hero={
        //   frontmatter.useBigHero ? (
        //     <BigHero message={frontmatter.heroMessage} />
        //   ) : undefined
        // }
        frontmatter={frontmatter}
        // headerColor={theme.colors.white}
      >
        <Container
          maxWidth={frontmatter.maxWidth}
          className="prose"
        //   css={css`
        //     ${bpMaxSM} {
        //       padding: 20px
        //         ${frontmatter.noMobileHorizontalPadding ? 0 : '20'}px;
        //     }
        //   `}
        >
          {children}
        </Container>
      </Layout>
    </>
  )
}

export default MarkdownPage
